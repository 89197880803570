<template>
	<div id="UserCenter">
		<!-- 用户中心 -->
		<div class="banner">
			<el-image class="background" :src="banner" fit='cover'></el-image>
			<b class="mask"></b>
			<div class="main">
				<div class="cont">
					<p>参与投稿</p>
					<b>项目周期：2023年3月-2023年12月</b>
					<b>主办单位：北京生命绿洲公益服务中心</b>
					<b>鸣谢支持：<img :src="require('@/assets/imgs/QL_logo.png')" alt=""></b>
				</div>
			</div>
		</div>
		<div class="user" v-if="userInfo">
			<el-avatar :src="require('../../assets/imgs/userbase.png')" icon="el-icon-user-solid"></el-avatar>
			<el-divider direction="vertical"></el-divider>
			<div class="tougao">
				<p>{{Statistics&&Statistics.ALLNUM ? Statistics.ALLNUM : '0'}}</p>
				<b>投稿数</b>
			</div>
			<el-divider direction="vertical"></el-divider>
			<div class="tongguo">
				<p>{{Statistics&&Statistics.SUCCESSNUM ? Statistics.SUCCESSNUM : '0'}}</p>
				<b>入围数</b>
			</div>
			<el-divider direction="vertical"></el-divider>
			<div class="opts">
				<el-button @click="logOut">退出登录</el-button>
			</div>
		</div>
		<div class="user" v-if="!userInfo">
			<el-avatar :src="require('../../assets/imgs/userbase2.png')" icon="el-icon-user-solid"></el-avatar>
			<el-divider direction="vertical"></el-divider>
			<div class="tougao">
				<p>-</p>
				<b>投稿数</b>
			</div>
			<el-divider direction="vertical"></el-divider>
			<div class="tongguo">
				<p>-</p>
				<b>入围数</b>
			</div>
			<el-divider direction="vertical"></el-divider>
			<div class="opts">
				<el-button @click="loginDialogFun('登录')">登录</el-button>
				<el-button @click="loginDialogFun('注册')">注册</el-button>
			</div>
		</div>
		<div class="part3">
			<div class="tit">
				<p>参与投稿</p>
				<b>participate in activities</b>
			</div>

			<div class="cont">
				<dl @click="registerDialogCk() ">
					<dt>
						<img src="../../assets/imgs/grzc.svg" alt="">
					</dt>
					<dd>完善资料</dd>
				</dl>
				<dl @click="contributeDialogCk()">
					<dt>
						<img src="../../assets/imgs/wzsc.svg" alt="">
					</dt>
					<dd>立即投稿</dd>
				</dl>
				<dl @click="download(`/article/template/downloadTemplate`)">
					<dt>
						<img src="../../assets/imgs/mbxz.svg" alt="">
					</dt>
					<dd>资料下载</dd>
				</dl>
				<dl @click="download(`/article/template/downloadInvitation`)">
					<dt>
						<img src="../../assets/imgs/yqh.svg" alt="">
					</dt>
					<dd>邀请函下载</dd>
				</dl>
			</div>
		</div>
		<div class="part2">
			<div class="tit">
				<p>投稿记录</p>
				<b>Submission Record</b>
			</div>

			<div class="cont">
				<div class="document">
					<el-table :data="tableData" style="width: 100%">
						<el-table-column label="编号" width="70">
							<template slot-scope="scope">
								{{scope.row.id}}
							</template>
						</el-table-column>
						<el-table-column>
							<template slot="header" slot-scope="scope">
								文档名称
							</template>
							<template slot-scope="scope">
								{{scope.row.documentName}}
							</template>
						</el-table-column>
						<el-table-column prop="address" label="类型" width="100">
							<template slot-scope="scope">
								<span v-if="scope.row.documentType == 'TEXT'">文档</span>
								<span v-if="scope.row.documentType == 'VEDIO'">视频</span>
								<span v-if="scope.row.documentType == 'MANGA'">漫画</span>
							</template>
						</el-table-column>
						<el-table-column prop="date" label="上传时间" sortable width="150">
							<template slot-scope="scope">
								{{ $moment(scope.row.createTime).format('YYYY/MM/DD HH:mm') }}
							</template>
						</el-table-column>
						<el-table-column prop="date" label="审核时间" sortable width="150">
							<template slot-scope="scope">
								<template v-if="scope.row.changeTime">
									{{ $moment(scope.row.changeTime).format('YYYY/MM/DD HH:mm') }}
								</template>
								<template v-else>未审核</template>
							</template>
						</el-table-column>
						<el-table-column label="状态" width="120">
							<template slot-scope="scope">
								<span v-if="scope.row.documentStatus == 'WAIT'">
									<i class="el-icon-info" style="color: #565867;">待审核</i>
								</span>
								<span v-if="scope.row.documentStatus == 'SUCCESS'">
									<i class="el-icon-success" style="color:green;">审核完成</i>
								</span>
								<span v-if="scope.row.documentStatus == 'ERROR'">
									<el-popover placement="top" title="" width="200" trigger="hover"
										:content="scope.row.errorMessage" v-if="scope.row.errorMessage">
										<i slot="reference" class="el-icon-error" style="color:brown;">审核未通过</i>
									</el-popover>
									<i slot="reference" class="el-icon-error" style="color:brown;" v-else>审核未通过</i>
								</span>
								<span v-if="scope.row.documentStatus == 'OVER'">
									<i class="el-icon-success" style="color:#999;">已完成</i>
								</span>
							</template>
						</el-table-column>
						<el-table-column label="操作" width="130">
							<template slot-scope="scope">
								<el-button type="text" class="look" @click="pageToDetails(scope.row)">查看</el-button>
								<el-button type="text" class="updata" @click="updata(scope.row)"
									:disabled="scope.row.documentStatus == 'SUCCESS'">重新上传</el-button>
								<!-- <el-button type="text" class="delete" :disabled="scope.row.documentStatus == 'SUCCESS'"
									@click="delDocumenteFun(scope.row)">删除</el-button> -->
							</template>
						</el-table-column>
					</el-table>
				</div>

				<el-pagination background layout="prev, pager, next" :total="total" :page-size="pageSize"
					@current-change="handleCurrentChange" :current-page.sync="pageNum"
					:hide-on-single-page="true"></el-pagination>
			</div>
		</div>

		<el-dialog :visible.sync="loginDialog" width="600px" :show-close="false" :destroy-on-close="true">
			<div class="top" v-if="logType=='登录'">
				<p>登录</p>
				<b>Login</b>
			</div>
			<div class="top" v-if="logType=='注册'">
				<p>注册</p>
				<b>register</b>
			</div>
			<div class="top" v-if="logType=='忘记密码'">
				<p>重置密码</p>
				<b>reset password</b>
			</div>
			<login @loginSuccess="loginSuccessFun($event)" @toRegister="toRegister($event)"></login>
		</el-dialog>

		<el-dialog :visible.sync="registerDialog" width="900px" :show-close="false" :destroy-on-close="true">
			<div class="top">
				<p>完善资料</p>
				<b>Improve information</b>
			</div>
			<register @editUserSuccess="registerSuccessFun($event)"></register>
		</el-dialog>

		<el-dialog :visible.sync="contributeDialog" width="900px" :show-close="false" :destroy-on-close="true">
			<div class="top">
				<p>投稿</p>
				<b>contribute</b>
			</div>
			<contribute @uploadSuccess="uploadSuccessFun($event)"></contribute>
		</el-dialog>
	</div>
</template>

<script>
	import register from '@/components/userCenter/register'
	import login from '@/components/userCenter/login'
	import contribute from '@/components/userCenter/contribute'
	import baseUrl from "@/request/config.js"
	import {
		getConfig, // 首页获取logo banner 流程图  
		getUser, // 获取用户信息
		myStatistics, // 获取用户投稿信息
		myDocument, // 获取用户的投稿记录列表
		delDocument, // 删除文档
		viewDocument // 校验文档
	} from '@/request/api.js';
	import {
		set
	} from 'vue';
	export default {
		name: 'UserCenter',
		components: {
			register,
			login,
			contribute
		},
		data() {
			return {
				banner: '',
				userInfo: '',
				Statistics: '', // 用户投稿信息
				baseUrl: '',

				loginDialog: false,
				registerDialog: false,
				contributeDialog: false,
				pageSize: 10,
				total: 0,
				pageNum: 1,
				tableData: [],

				logType: '注册'
			}
		},
		created() {
			this.baseUrl = baseUrl;
			this.getUserFun();
			this.getConfigFun();
			this.getMyStatistics();
			this.getMyDocument();

		},
		mounted() {
			this.options();
		},
		watch: {
			"$store.state.logType": {
				handler(val) {
					this.logType = val;
				}
			}
		},
		methods: {
			loginDialogFun(item) {
				this.logType = item;
				this.$store.state.logType = item
				this.loginDialog = true;
			},

			// 下载模版、邀请函
			download(link) {
				window.open(`${this.baseUrl}${link}`)
			},

			// 快捷操作
			options() {
				let query = this.$route.query.opt;
				if (query == 'register') {
					this.loginDialogFun('注册');
				} else if (query == 'contribute') {
					setTimeout(() => {
						this.contributeDialogCk();
					}, 300)
				}
			},

			// 完善信息
			registerDialogCk() {
				if (this.userInfo) {
					this.registerDialog = true;

				} else {
					this.$confirm('您还未登录，是否现在登录?', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'info'
					}).then(() => {
						this.loginDialog = true;
						this.$store.state.logType = '登录';
						this.logType = '登录'
					})
				}
			},

			// 立即投稿
			contributeDialogCk() {
				if (!this.userInfo) {
					this.$confirm('您还未登录，是否现在登录?', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'info'
					}).then(() => {
						this.loginDialog = true;
						this.$store.state.logType = '登录';
						this.logType = '登录'
					})
				} else if (!this.userInfo.fullName) {
					this.$confirm('您需要完善个人信息后才能参与投稿，是否现在去完善信息?', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'info'
					}).then(() => {
						this.registerDialog = true;
					})
				} else {
					this.contributeDialog = true;
				}
			},

			// 获取我的投稿记录
			getMyDocument() {
				let data = {
					current: this.pageNum,
					size: this.pageSize,
				};
				myDocument(data).then(res => {
					if (res.code == 200) {
						this.total = res.data.total;
						this.tableData = res.data.records;
					}
				}).catch(err => {
					console.log(err)
				})
			},

			// 获取用户投稿信息
			getMyStatistics() {
				myStatistics().then(res => {
					if (res.code == 200) {
						this.Statistics = res.data
					}
				}).catch(err => {
					console.log(err)
				})
			},

			// 获取用户信息
			getUserFun() {
				getUser().then(res => {
					if (res.code == 200) {
						this.userInfo = res.data;
					} else {

					}
				})
			},
			// getUserFun2() {
			// 	getUser().then(res => {
			// 		if (res.code == 200) {
			// 			this.userInfo = res.data;
			// 			if (!this.userInfo.cardId || !this.userInfo.fullName) {
			// 				this.$confirm('您需要完善个人资料后才可以进行投稿，现在否去完善资料？', '提示', {
			// 					confirmButtonText: '去完善资料',
			// 					type: 'info'
			// 				}).then(() => {
			// 					this.registerDialog = true;
			// 				}).catch(err => {
			// 					console.log(err)
			// 				})
			// 			}
			// 		}
			// 	})
			// },

			// 登录成功后
			loginSuccessFun(e) {
				if (e.type) {
					this.loginDialog = false;
					this.getUserFun();
					this.getMyStatistics();
					this.getMyDocument();
				}
			},

			// 去注册
			toRegister(e) {
				if (e.type) {
					this.loginDialog = false;
					this.registerDialog = true;
				}
			},

			// 退出登录
			logOut() {
				this.$confirm('是够确认退出登录?', '确认', {
					confirmButtonText: '确定',
					type: 'warning'
				}).then(() => {
					this.getUserFun();
					this.getMyStatistics();
					this.getMyDocument();
					localStorage.clear();
					this.tableData = [];
					this.userInfo = '';
					this.$notify({
						title: '退出成功',
						message: `退出成功`,
						type: 'success',
						position: 'bottom-right'
					});
					this.$route.go(0)
				})
			},

			// 投稿成功后子组件传值
			uploadSuccessFun(e) {
				if (e.type) {
					this.contributeDialog = false;
					this.getMyStatistics();
					this.getMyDocument();
				}
			},

			// 注册成功后子组件传值
			registerSuccessFun(e) {
				if (e.type) {
					this.registerDialog = false;
				}
				this.getUserFun();
			},

			filterHandler(value, row, column) {
				console.log(value)
			},

			pageToDetails(row) {
				console.log(row.documentPath)
				viewDocument({
					id: row.id
				}).then(res => {
					if (res.code == 200) {
						window.open(`${res.data}`)
					} else {
						this.$notify({
							title: '查看失败',
							message: `${res.msg}`,
							type: 'error',
							position: 'bottom-right'
						});
					}
				})
			},

			// 上传与重新上传
			updata(row) {
				if (row && row.id) {
					this.$store.state.documentId = row.id;
					setTimeout(() => {
						this.contributeDialog = true;
					}, 100)
				}
			},

			// 删除文档
			delDocumenteFun(row) {
				this.$confirm('您确定要删除这条投稿么？', '确认', {
					confirmButtonText: '确定删除',
					cancelButtonText: '取消',
					type: 'success'
				}).then(() => {
					let data = {
						id: row.id
					}
					delDocument(data).then(res => {
						if (res.code == 200) {
							this.$notify({
								title: '删除成功',
								message: `${res.msg}`,
								type: 'success',
								position: 'bottom-right'
							});
							this.getMyStatistics();
							this.getMyDocument();
						} else {
							this.$notify({
								title: '删除失败',
								message: `${res.msg}`,
								type: 'error',
								position: 'bottom-right'
							});
						}

					}).catch(err => {
						console.log(err)
					})
				})

			},

			TabsChange(val) {
				console.log(val)
			},

			handleCurrentChange(val) {
				this.pageNum = val
				this.getMyDocument();
			},
			// 首页获取logo banner 流程图
			getConfigFun() {
				getConfig().then(res => {
					if (res.code == 200) {
						this.banner = res.data.bannerPath;
					}
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	#UserCenter {
		::v-deep .el-dialog {
			border-radius: 10px;

			.el-dialog__header {
				padding: 0;
			}

			.el-dialog__body {
				padding: 50px 20px;
			}

			.top {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;

				p {
					font-size: 36px;
					color: rgba(31, 32, 34, 1);
				}

				b {
					font-size: 18px;
					color: rgba(203, 50, 44, 1);
				}
			}
		}

		.banner {
			min-height: 60vh;
			position: relative;

			.webHander {
				position: absolute;
				left: 0;
				top: 0;
				right: 0;
				z-index: 1;
			}

			.background {
				position: absolute;
				width: 100%;
				height: 100%;
				z-index: 0;
			}

			.mask {
				position: absolute;
				left: 0;
				top: 0;
				right: 0;
				bottom: 0;
				z-index: 0;
				background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(252, 253, 255, 1) 100%)
			}

			.main {
				position: absolute;
				width: 1200px;
				top: 0;
				bottom: 0;
				left: 50%;
				transform: translateX(-50%);
				z-index: 0;
				display: flex;
				flex-direction: column;
				justify-content: flex-end;
				align-items: flex-start;
				padding-bottom: 150px;

				.cont {
					display: flex;
					flex-direction: column;

					p {
						font-size: 70px;
						font-weight: 600;
						line-height: 1.5;
					}

					b {
						font-size: 16px;
						font-weight: normal;
						margin-top: 12px;
						color: #000;
						opacity: .6;
						line-height: 1.5;

						img {
							height: 36px;
						}
					}
				}
			}
		}

		.user {
			width: 1200px;
			margin: 5px auto 0;
			border-bottom: 2px solid rgba(229, 229, 229, .6);
			border-top: 2px solid rgba(229, 229, 229, .6);
			display: flex;
			flex-direction: row;
			justify-content: space-around;
			align-items: center;
			padding: 20px 0;

			.el-divider {
				height: 100px;
				width: 2px;
			}

			.el-avatar {
				width: 106px;
				height: 106px;
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: 50px;
			}

			.el-button {
				width: 150px;
				height: 50px;
				border-radius: 8px;
				background: rgba(203, 50, 44, 0);
				border: 1px dashed rgba(203, 50, 44, 1);
				transition: all .3s;
				font-size: 16px;
				padding: 0;
				margin: 5px 0;

				&:hover {
					color: rgba(203, 50, 44, 1);
					border: 1px solid rgba(203, 50, 44, 1);
				}
			}

			div {
				display: flex;
				flex-direction: column;

				p {
					font-size: 60px;
					color: rgba(203, 50, 44, 1);
					text-align: center;
				}

				b {
					font-size: 28px;
					color: #333;
					font-weight: normal;
					text-align: center;
				}
			}
		}

		.part3 {
			padding: 100px 0 0;

			.tit {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				margin-bottom: 60px;

				p {
					font-size: 36px;
					text-align: center;
					color: rgba(31, 32, 34, 1);
				}

				b {
					font-size: 18px;
					text-align: center;
					color: rgba(203, 50, 44, 1);
				}
			}

			.cont {
				width: 1200px;
				margin: auto;
				display: flex;
				flex-direction: row;
				justify-content: space-around;

				dl {
					display: flex;
					flex-direction: column;
					justify-content: center;
					cursor: pointer;

					* {
						cursor: pointer;
					}

					dt {
						font-size: 40px;
						width: 160px;
						height: 160px;
						border-radius: 40px;
						border: 2px solid #f4f4f4;
						display: flex;
						align-items: center;
						justify-content: center;
						box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.08);

						img {
							width: 100px;
							height: 100px;
						}

					}

					dd {
						font-size: 28px;
						color: rgba(0, 0, 0, 1);
						text-align: center;
						margin-top: 10px;
					}
				}
			}
		}

		.part2 {
			padding: 100px 0 0;

			.tit {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				margin-bottom: 60px;

				p {
					font-size: 36px;
					text-align: center;
					color: rgba(31, 32, 34, 1);
				}

				b {
					font-size: 18px;
					text-align: center;
					color: rgba(203, 50, 44, 1);
				}
			}

			.cont {
				width: 1200px;
				margin: auto;
				padding: 40px 35px;
				box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.05);

				::v-deep .el-tabs {

					.el-tabs__item {
						font-size: 20px;

						&:hover {
							color: rgba(203, 50, 44, .8);
						}
					}

					.el-tabs__item.is-active {
						color: rgba(203, 50, 44, 1);
					}

					.el-tabs__active-bar {
						background-color: rgba(203, 50, 44, 1);
					}
				}

				.el-pagination {
					text-align: center;
					margin-top: 20px;
				}

				.document,
				.video,
				.comic {
					display: flex;
					flex-direction: row;
					flex-wrap: wrap;
					justify-content: space-between;

					dl {
						width: 31%;
						margin-bottom: 30px;


						dt {
							.el-image {
								width: 100%;
								height: 180px;
								border-radius: 4px;
							}
						}

						dd {
							font-size: 14px;
							color: rgba(102, 102, 102, 1);
							margin-top: 18px;
						}
					}
				}
			}
		}
	}
</style>