<template>
	<div id="contribute" v-loading="loading" element-loading-text="正在处理请求请稍候">
		<!-- 投稿 -->
		<el-form :model="registerData" :rules="rules" ref="registerData" label-position="left">
			<el-form-item label="文档名称" prop="documentName">
				<el-input placeholder="请输入文档名称" v-model="registerData.documentName" clearable></el-input>
			</el-form-item>
			<el-form-item label="文档类型" prop="documentType">
				<el-select v-model="registerData.documentType" filterable placeholder="文档类型" clearable>
					<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
					</el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="上传文档" prop="documentPath">
				<el-upload class="upload-demo" :action="`${baseUrl}/article/document/uploadFile`"
					:on-success="handleSuccess" multiple :before-upload="beforeUpload" :limit="1"
					:on-exceed="handleExceed" :file-list="fileList" :disabled="disabled">
					<el-button type="primary" plain>上传文档</el-button>
					<div class="el-upload__tip" slot="tip">限制单次上传1个文件，大小在500M以内。</div>
				</el-upload>
			</el-form-item>
			<el-form-item label="所属领域" prop="areaId">
				<el-checkbox-group v-model="registerData.areaId" :max="1">
					<el-checkbox :label="item.id" v-for="item in Aarealist"
						:key="item.id">{{item.areaName}}</el-checkbox>
				</el-checkbox-group>
			</el-form-item>
			<el-form-item label="" class="checked">
				<el-checkbox v-model="checked"></el-checkbox>
				<a href="https://qhjh.life-oasis.com/article/article/document/getOssFile/af133353026a443aa54a83388a7e63b9_4-%E4%BD%9C%E5%93%81%E5%8E%9F%E5%88%9B%E5%8F%8A%E6%8E%88%E6%9D%83%E5%A3%B0%E6%98%8E(1).pdf?key=1124258136325230592"
					target="_blank">作品原创及授权声明</a>
			</el-form-item>
			<el-form-item class="options">
				<!-- <el-button class="reset" @click="resetForm('registerData')">重置</el-button> -->
				<el-button class="submit" @click="submitForm('registerData')" :disabled="disabled">提交</el-button>
			</el-form-item>
		</el-form>
	</div>
</template>
<script>
	import baseUrl from "@/request/config.js"
	import {
		register, // 注册
		saveOrUpdate, // 保存或修改
		arealist // 领域列表
	} from '@/request/api.js';
	export default {
		name: 'contribute',
		data() {
			return {
				baseUrl: '',
				registerData: {
					documentName: "", // 文档名称					
					documentType: "", // 文档类型
					documentPath: "", // 文档路径
					id: '',
					areaId: [], // 领域
				},
				checked: false, // 授权声明
				Aarealist: [],
				rules: {
					documentName: [{
						required: true,
						message: '请输入文档名称',
						trigger: 'blur'
					}, {
						min: 2,
						max: 100,
						message: '长度在 2 到 100 个字符',
						trigger: 'blur'
					}],
					documentType: [{
						required: true,
						message: '请选择文档类型',
						trigger: 'change|blur'
					}],
					documentPath: [{
						required: true,
						message: '请上传文档',
						trigger: 'change'
					}],
					areaId: [{
						required: true,
						message: '请选择文档所属领域',
						trigger: 'change'
					}]
				},
				options: [{
					value: 'TEXT',
					label: '文档'
				}, {
					value: 'AUDIO',
					label: '音频'
				}, {
					value: 'VEDIO',
					label: '视频'
				}, ],
				fileList: [],
				fileList2: [],

				loading: false,
				disabled: false
			}
		},
		created() {
			this.baseUrl = baseUrl;
			this.getarealist();
		},
		mounted() {},
		watch: {
			'$store.state.documentId': {
				handler(val) {
					// console.log(val, '2')
					this.registerData.id = val;
				},
				deep: true,
				immediate: true

			}
		},
		methods: {
			// 获取领域列表
			getarealist() {
				arealist().then(res => {
					if (res.code == 200) {
						console.log(res)
						this.Aarealist = res.data
					}
				})
			},
			handleSuccess(res, file) {
				this.disabled = false;
				this.registerData.documentPath = res.data;
				if (!this.registerData.documentName) {
					this.registerData.documentName = file.name;
				}
				let type = file.raw.type.split("/")[0];
				if (type == 'video') {
					this.registerData.documentType = 'VEDIO';
				} else if (type == 'audio') {
					this.registerData.documentType = 'AUDIO';
				} else {
					this.registerData.documentType = 'TEXT';
				}
			},

			beforeUpload() {
				this.disabled = true;
			},

			submitForm(formName) {
				if (this.checked) {
					this.$refs[formName].validate((valid) => {
						if (valid) {
							this.loading = true;
							saveOrUpdate(this.registerData).then(res => {
								if (res.code == 200) {
									this.resetForm('registerData');
									this.fileList = [];
									this.$emit('uploadSuccess', {
										type: 'success',
										login: true
									});
									this.$notify({
										title: '投稿成功',
										message: `投稿成功`,
										type: 'success',
										position: 'bottom-right'
									});
									this.loading = false;
								} else {
									this.$notify({
										title: '投稿失败',
										message: `${res.msg}`,
										type: 'error',
										position: 'bottom-right'
									});
									this.loading = false;
								}
							}).catch(err => {
								console.log(err);
								this.resetForm('registerData');
							})
						} else {
							console.log('error submit!!');
							return false;
						}
					});
				} else {
					this.$notify({
						title: '提示',
						message: `请阅读并确认作品原创及授权声明`,
						type: 'error',
						position: 'bottom-right'
					});
				}

			},

			handleExceed(files, fileList) {
				this.$notify({
					title: '提示',
					message: `只允许单个文件上传，如需修改上传的文件 请先删除列表中的文件。`,
					type: 'error',
					position: 'bottom-right'
				});
			},

			resetForm(formName) {
				this.$refs[formName].resetFields();
			}
		}
	}
</script>

<style lang="scss" scoped>
	#contribute {
		::v-deep .el-form {
			margin: 50px 50px 0;

			.el-form-item {
				display: flex;
				align-items: center;
				margin-bottom: 40px;

				.el-form-item__label {
					font-size: 20px;
					color: rgba(47, 52, 71, 1);
					width: 200px;
				}

				.el-form-item__content {
					flex: 1;
					margin: 0;
					display: flex;

					* {
						font-size: 16px;
					}

					.el-input {
						flex: 1;
						height: 80px;

						.el-input__inner {
							height: 100%;
						}

						.el-input-group__append {
							button {
								height: 100%;
							}
						}
					}

					.el-select {
						width: 100%;
					}

					.el-form-item__error {
						font-size: 14px;
						color: #F56C6C;
					}
				}
			}

			.checked {

				.el-form-item__content {

					display: flex;
					justify-content: center;
					align-items: center;

					a {
						margin-left: 6px;
						text-decoration: underline;
						line-height: 1.5;
					}
				}

				.el-form-item__error {
					width: 100%;
					text-align: center;
				}
			}

			.options .el-form-item__content {
				display: flex;
				align-items: center;
				justify-content: center;

				.el-button {
					width: 180px;
					height: 80px;
				}

				.submit {
					background-color: rgba(203, 50, 44, 1);

					* {
						color: #fff;
					}
				}
			}
		}
	}
</style>