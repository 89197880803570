<template>
	<div id="editUser">
		<el-form :model="editUserData" :rules="rules" ref="editUserData" label-position="left">
			<el-form-item label="投稿人姓名" prop="fullName">
				<el-input placeholder="请输入投稿人姓名" v-model="editUserData.fullName"></el-input>
			</el-form-item>
			<!-- <el-form-item label="身份证号" prop="cardId">
				<el-input placeholder="请输入投稿人身份证号" v-model="editUserData.cardId"
					:disabled="userInfo.cardId && userInfo.cardId!=''"></el-input>
			</el-form-item> -->
			<el-form-item label="所属机构" prop="hospital">
				<el-select v-model="editUserData.hospital" filterable remote placeholder="请选择所属机构" clearable
					:remote-method="getHospitalList" :loading="loading" @change="getHospitalList()">
					<el-option label="其他机构" value="other"></el-option>
					<el-option v-for="(item,i) in options" :key="i" :label="item.hospitalName"
						:value="item.hospitalName">
					</el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="所属科室" prop="department">
				<el-input placeholder="请输入所在科室" v-model="editUserData.department"></el-input>
			</el-form-item>
			<el-form-item label="手机号" prop="phoneNumber">
				<el-input v-model="editUserData.phoneNumber" placeholder="请输入手机号" :max="11">
					<el-button slot="append" @click="sendCodeFun(editUserData.phoneNumber)" :disabled="disabled">
						{{textCode}}
					</el-button>
				</el-input>
			</el-form-item>
			<el-form-item label="验证码" prop="verifyCode">
				<el-input v-model="editUserData.verifyCode" placeholder="请输入验证码"></el-input>
			</el-form-item>
			<!-- <el-form-item label="银行卡号" prop="bankNumber">
				<el-input v-model="editUserData.bankNumber" placeholder="请输入银行卡号以作后期费用结算"></el-input>
			</el-form-item>
			<el-form-item label="开户行" prop="bankName">
				<el-input placeholder="请输入开户行" v-model="editUserData.bankName"></el-input>
			</el-form-item> -->
			<el-form-item class="options">
				<!-- <el-button class="reset" @click="resetForm('editUserData')">重置</el-button> -->
				<el-button class="submit" @click="submitForm('editUserData')">提交</el-button>
			</el-form-item>
		</el-form>

	</div>
</template>

<script>
	import {
		getUser, // 获取用户信息
		editUser, // 注册
		sendCode, // 获取验证码
		verify, // 校验验证码
		hospitalList // 获取机构列表
	} from '@/request/api.js';
	export default {
		name: 'editUser',
		data() {
			return {
				editUserData: {
					fullName: "", // 用户姓名
					cardId: '', // 身份证号
					bankNumber: '', // 	银行卡号
					bankName: '', // 开户行
					hospital: '', // 所属医院
					department: '', // 所属科室
					phoneNumber: ''
				},
				userInfo: '',
				miaoCode: 60,
				textCode: '获取验证码',
				disabled: false,
				rules: {
					fullName: [{
						required: true,
						message: '请输入投稿人姓名',
						trigger: 'blur'
					}, {
						min: 2,
						max: 8,
						message: '长度在 2 到 8 个字符',
						trigger: 'blur'
					}],
					cardId: [{
						required: false,
						message: '请输入身份证号',
						trigger: 'blur'
					}, {
						required: false,
						message: '请输入正确的身份证号',
						pattern: /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,
						trigger: 'blur',
					}],
					hospital: [{
						required: true,
						message: '请选择所属机构',
						trigger: 'change|blur'
					}],
					department: [{
						required: true,
						message: '请输入所在科室',
						trigger: 'blur'
					}],
					phoneNumber: [{
						required: false,
						message: '请输入手机号',
						trigger: 'blur'
					}, {
						required: false,
						message: '请输入正确的手机号',
						pattern: /^1[3-9]\d{9}$/,
						trigger: 'blur',
					}],
					verifyCode: [{
						required: false,
						message: '请输入验证码',
						trigger: 'blur'
					}, {
						required: false,
						message: '请输入正确的验证码',
						pattern: /^[A-Za-z0-9]{4,6}$/,
						trigger: 'change',
					}],
					bankNumber: [{
						required: false,
						message: '请输入银行卡号',
						trigger: 'blur'
					}],
					bankName: [{
						required: false,
						message: '请输入开户行名称',
						trigger: 'blur'
					}],
				},
				options: [{
					hospitalName: "清华大学第一附属医院北京华信医院 （原酒仙桥医院）"
				}, {
					hospitalName: "沈阳市第一人民医院"
				}],
				list: [],
				loading: false
			}
		},
		created() {
			this.getHospitalList();
			this.getUserFun();
		},
		mounted() {},
		methods: {
			// 获取用户信息
			getUserFun() {
				getUser().then(res => {
					if (res.code == 200) {
						let data = res.data;
						this.userInfo = data;

						this.editUserData.fullName = data.fullName;
						this.editUserData.cardId = data.cardId;
						this.editUserData.bankNumber = data.bankNumber;
						this.editUserData.bankName = data.bankName;
						this.editUserData.hospital = data.hospital;
						this.editUserData.department = data.department;
						this.editUserData.phoneNumber = data.phoneNumber;
					}
				})
			},

			// 获取机构列表
			getHospitalList(query) {
				this.loading = true;
				let data = {};
				data.hospitalName = query;
				hospitalList(data).then(res => {
					this.loading = false;
					if (res.code == 200) {
						this.options = res.data.records;
					}
				})
			},

			// 提交表单
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						if ((this.userInfo.fullName && this.userInfo.fullName != '') || (this.userInfo.fullName &&
								this.userInfo.fullName != '')) {
							this.editUserFun();
						} else {
							// this.$confirm('姓名和身份证后一旦提交无法修改，是否提交？', '提示', {
							this.$confirm('一旦提交后姓名无法修改，请再确认', '提示', {
								confirmButtonText: '提交',
								type: 'info'
							}).then(() => {
								this.editUserFun();
							}).catch(err => {
								console.log(err)
							})
						}
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},

			editUserFun() {
				editUser(this.editUserData).then(res => {
					if (res.code == 200) {
						this.$notify({
							title: '提交信息成功',
							message: `${res.msg}`,
							type: 'success',
							position: 'bottom-right'
						});
						this.$emit('editUserSuccess', {
							type: 'success',
							login: false
						})
					} else {
						this.$notify({
							title: '提交失败',
							message: `${res.msg}`,
							type: 'error',
							position: 'bottom-right'
						});
					}
				});
			},

			resetForm(formName) {
				this.$refs[formName].resetFields();
			},

			// 获取手机验证码
			sendCodeFun(code) {
				if (!code) {
					this.$notify({
						title: '提示',
						message: `请输入手机号`,
						type: 'error',
						position: 'bottom-right'
					});
				} else if (!(/^1[3-9]\d{9}$/.test(code))) {
					this.$notify({
						title: '提示',
						message: `请输入正确的手机号`,
						type: 'error',
						position: 'bottom-right'
					});
				} else {
					this.disabled = true;
					this.countdown(this.miaoCode);
					sendCode(code).then(res => {
						if (res.code == 200) {
							this.$notify({
								title: '验证码发送成功',
								message: `验证码发送成功，请及时查看。60秒内请勿重复获取。`,
								type: 'success',
								position: 'bottom-right'
							});
						} else {
							this.$notify({
								title: '验证码发送失败',
								message: `${res.msg}`,
								type: 'error',
								position: 'bottom-right'
							});
						}
					}).catch(err => {
						console.log(err)
					})
				}
			},

			// 倒计时
			countdown(miaoCode) {
				this.textCode = `${miaoCode}秒后重新获取`;
				setTimeout(() => {
					if (miaoCode > 0) {
						this.miaoCode--;
						this.textCode = `${miaoCode}秒后重新获取`;
						this.countdown(this.miaoCode)
					} else {
						this.disabled = false;
						this.textCode = '获取验证码';
						this.miaoCode = 60;
					}
				}, 1000)
			},
		}
	}
</script>

<style lang="scss" scoped>
	#editUser {
		// margin: 60px 0 30px;

		::v-deep .el-form {
			margin: 50px 50px 0;

			.el-form-item {
				display: flex;
				align-items: center;

				.el-form-item__label {
					font-size: 20px;
					color: rgba(47, 52, 71, 1);
					width: 150px;
				}

				.el-form-item__content {
					height: 80px;
					flex: 1;
					margin: 0;
					display: flex;

					* {
						font-size: 16px;
					}

					.el-input {
						flex: 1;
						height: 100%;

						.el-input__inner {
							height: 100%;
						}

						.el-input-group__append {
							button {
								height: 100%;
							}
						}
					}

					.el-select {
						width: 100%;
					}

					.el-form-item__error {
						font-size: 14px;
						color: #F56C6C;
					}
				}
			}

			.options .el-form-item__content {
				display: flex;
				align-items: center;
				justify-content: center;

				.el-button {
					width: 180px;
					height: 80px;
				}

				.submit {
					background-color: rgba(203, 50, 44, 1);

					* {
						color: #fff;
					}
				}
			}
		}
	}
</style>